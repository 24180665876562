import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Why People Are Choosing Clear Correct Invisible Braces | Blog" 
    description="Want to straighten your teeth? We offer the best options for teeth straightening using clear correct invisible braces. Contact us today for a perfect smile consultation." 
    keywords="Smile Consultation" />
    <main className="site-content prindex" role="main">
    <section id="main-top-img" className="blog hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <div className="blog-news-art">
              <h1>Why more people are choosing Clear Correct Invisible Braces</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p>Braces have infamously been connected with bulky, and unattractive hunks of metal in a patients mouth. However, with present day technology and improvements in dental procedures, the days of only having to choose those bulky metal braces might be over. More and more dental professionals and patients are choosing Clear Correct Invisible braces as their choice in corrective measures. So why is this?</p>
            <p>Simply put, Clear Correct Invisible braces offers patients a custom and faster treatment option when compared to traditional braces. To showcase this, we are going to look at some simple benefits of this revolutionary technology.</p>
            <img alt="Blog Banner" src={ require("../../images/desktop/Clear Braces - Dentist Toronto.png" )} className="bl__img" />
            <h3>Strengthening Process</h3>
            <p>When you look at Clear Correct Invisible braces for your corrective measures, you choose a proven and safe option for your dental needs. The technology includes removable plastic aligners that work over some months to gradually shift your teeth into position. For a patient to be successful, these aligners need to be worn consistently. </p>
            <h3>Appearance</h3>
            <p>The appearance of Clear Correct Invisible braces is designed to be clear. The aligners are clear plastic that allows its users to enjoy the benefits of braces, with a practically invisible trey instead of heavy metal.</p>
            <img alt="Strengthening Process" src={ require("../../images/desktop/Clear Correct Smile Stylist Braces.png" )} className="bl__img" />
            <h3>Health Benefits</h3>
            <p>One of the significant advantages of Clear Correct Invisible braces is that they offer their users a better overall oral health regime. The reason for this is the aligners are easily removable which allows for flossing, brushing and teeth cleaning without the issues that are associated with traditional braces. Plus, without the conventional anchors on your teeth, you will enjoy teeth without the marks and darkening that is generally associated with metal braces. </p>
            <h3>Procedure Length</h3>
            <p>Although this varies per patient, generally, Clear Correct Invisible brace treatments will last between 10 and 12 months. For the best treatment results, clients should wear their aligners as often as possible, as, without proper dedication to the treatment regime, clients will face more extended treatment options. However, when compared to traditional braces, Clear Correct has corrected its patient six months faster, and that is something we can all get behind. </p>
            <img alt="Invisible Braces" src={ require("../../images/desktop/Clear Correct Smile Stylist.png" )} className="bl__img" />
            <h3>Comfort</h3>
            <p>If you are someone with a sensitive mouth, traditional braces are not an ideal fit for you and your teeth. Clear Correct Invisible braces offer a solution that is not only smooth but custom fit to ensure your mouth feels excellent while correcting your dental concerns. </p>
            <p>There have been over 1,000,000 dental patients over the years that have trusted clear braces to straighten their teeth. Clear Correct is one of the leaders in the industry, and no matter if you are looking to straighten your teeth, or correct some other dental issues, their revolutionary technology will help you and your teeth out. The results speak for themselves, and we cannot wait to work with you to ensure your teeth are a showstopper. If you are interested in how Clear Correct Invisible braces can help you and your mouth out, call or click today to see our treatment options and how they can work with your budget! </p>
          </div>
          <div className="col-sm-4 blog-right">
            <a href="/virtual-smile-makeover"><img alt="Invisible Braces Comfort" src={ require("../../images/desktop/get-virtual-smile.png" )} className="img-responsive wow animated zoomIn" data-wow-delay="0.3s" /></a>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage